<template>
  <b-card-code id="tech-service-all" no-body>

    <b-row class="pageContent">
      <b-col class="filtersContainer" md="12">
        <div class="filters">
          <div :class="(controlFilter === 'ts') ? 'active' : ''" class="control-filter" @click="changeControllFilter('ts')">
            <div class="filter-label">Գույքահարկ։ </div>
            <div class="filter-value">{{ inspectionStatusCount }}</div>
          </div>
          <div :class="(controlFilter === 'dahk') ? 'active' : ''" class="control-filter" @click="changeControllFilter('dahk')">
            <div class="filter-label">ԴԱՀԿ։ </div>
            <div class="filter-value">{{ dahkPagination.total }}</div>
          </div>
          <b-button variant="primary" v-if="dahk.length > 0" @click="downloadInvoice" class="downloadInvoiceButton">Բեռնել հաշվետվությունը</b-button>
        </div>
      </b-col>
      <b-col v-if="controlFilter === 'ts'" md="12">
        <ag-grid-vue
            @gridReady="onGridReady"
            ref="agGridTable"
            :localeText="localization"
            :components="components"
            :gridOptions="gridOptions"
            :class="table_class"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            :rowData="checkups"
            rowSelection="multiple"
            colResizeDefault="shift"
            :animateRows="true"
            :floatingFilter="true"
            :pagination="true"
            :paginationPageSize="50"
            :suppressPaginationPanel="true"
            :enableRtl="$vs.rtl">
        </ag-grid-vue>
        <b-card-body class="table-footer d-flex justify-content-between flex-wrap pt-0 pb-0 align-items-center">

          <!-- page length -->
          <strong class="black">
            {{ pagination.total }} արդյունք
          </strong>

          <!-- pagination -->
          <div>
            <b-pagination
                @input="changePage"
                v-model="pagination.current_page"
                :total-rows="pagination.total"
                :per-page="pagination.count"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-col>
      <b-col v-if="controlFilter === 'dahk'" md="12">
        <ag-grid-vue
            @gridReady="onGridReady"
            ref="agGridTable"
            :localeText="localization"
            :components="components"
            :gridOptions="gridOptions"
            :class="table_class"
            :columnDefs="columnDefsDahk"
            :defaultColDef="defaultColDef"
            :rowData="dahk"
            rowSelection="multiple"
            colResizeDefault="shift"
            :animateRows="true"
            :floatingFilter="true"
            :pagination="true"
            :paginationPageSize="50"
            :suppressPaginationPanel="true"
            :enableRtl="$vs.rtl">
        </ag-grid-vue>
        <b-card-body class="table-footer d-flex justify-content-between flex-wrap pt-0 pb-0 align-items-center">

          <!-- page length -->
          <strong class="black">
            {{ dahkPagination.total }} արդյունք
          </strong>

          <!-- pagination -->
          <div>
            <b-pagination
                @input="changeDahkPage"
                v-model="dahkPagination.current_page"
                :total-rows="dahkPagination.total"
                :per-page="dahkPagination.count"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-col>
    </b-row>

    <table style="display: none" id="exportTable">
      <thead>
      <tr>
        <th>Կազմակերպություն</th>
        <th>Հարցում</th>
        <th>Համարանիշ</th>
        <th>Փաստաթուղթ</th>
        <th>Մեքենա</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item of allDahk">

        <td>{{ item.company.name }}</td>
        <td>{{ dateFormatter({value: item.createdAt}) }}</td>
        <td>{{ item.CarRegNo }}</td>
        <td>{{ item.documentNumber }}</td>
        <td>{{ item.car }}</td>
      </tr>
      </tbody>
    </table>

  </b-card-code>
</template>

<script>

import techServiceModule from '@/store/tech-service/techServiceModule'
import companyModule from '@/store/companies/companyModule'
import store from '@/store'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import Financial from '@/views/pages/tech-service/docs/Financial'
import {
  BAvatar,
  BBadge,
  BButton,
  BCardBody,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTable,
  BAlert,
  BDropdown,
  BDropdownItem
} from 'bootstrap-vue'
import { AgGridVue } from 'ag-grid-vue'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css'
import Form from '@/views/pages/tech-service/Form'
import CellRendererOpinion from '@/views/pages/tech-service/columns/CellRendererOpinion'
import CellRendererChanges from '@/views/pages/tech-service/columns/CellRendererChanges'
import CellRendererCanEdit from '@/views/pages/tech-service/columns/CellRendererCanEdit'
import CellRendererEdit from '@/views/pages/tech-service/columns/CellRendererEdit'
import AG_GRID_LOCALE_HY from '@core/global/ag-grid-hy'
import vSelect from 'vue-select'
import Classificator from '@/views/pages/tech-service/docs/Classificator'
import Fuel from '@/views/pages/tech-service/docs/Fuel'
import Info from '@/views/pages/tech-service/docs/Info'
import carsModule from '@/store/cars/cars'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import CellRendererStatus from '@/views/pages/tech-service/columns/CellRendererStatus'
import TableToExcel from "@linways/table-to-excel";

export default {
  name: 'tech-service-view',

  components: {
    vSelect,
    BCardCode,
    AgGridVue,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BForm,
    BRow,
    BCol,
    BTable,
    BAlert,
    BDropdown,
    BDropdownItem,
    Form,
    CellRendererOpinion,
    CellRendererChanges,
    CellRendererCanEdit,
    CellRendererEdit,
    Classificator,
    CellRendererStatus,
    Fuel,
    Info,
    Financial,
  },

  data() {
    return {
      controlFilter: 'ts',
      TECH_SERVICE_STORE_MODULE_NAME: 'techServiceModule',
      COMPANIES_STORE_MODULE_NAME: 'companiesModule',
      CARS_STORE_MODULE_NAME: 'carsModule',
      gridOptions: {
        getRowStyle: (params) => {
          if (params.data.CheckNoEdited) {
            return { 'background-color': '#ffc107' }
          }
        }
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      components: null,
      opinions: [
        {
          label: 'Սարքին',
          id: true
        },
        {
          label: 'Անսարք',
          id: false
        }
      ],
      allDahk: []
    }
  },

  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    let todayStart = new Date()
    todayStart.setHours(0, 0, 0, 0)

    let todayEnd = new Date()
    todayEnd.setHours(23, 59, 59, 59)

    const initialFilters = {
      Opinion: true,
      startDate: todayStart,
      endDate: todayEnd,
      search: '',
      company: '',
      fields: {}
    }

    return {
      skin,
      isDark,
      initialFilters
    }
  },

  computed: {

    isViewer() {
      return (atob(this.$store.state.user_type) === 'VIEWER')
    },

    companyInfo() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].companyInfo
    },

    checkupNumbers() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].checkupNumbers
    },

    search_filters() {
      return [
        {
          label: 'Գրանցման համարանիշի',
          id: 'CarRegNo'
        },
        {
          label: 'Գրանցման փաստաթղթի',
          id: 'documentNumber'
        },
        {
          label: 'Կտրոնի համարի',
          id: 'CheckNo'
        },
        {
          label: 'Նույնականացման VIN կոդի',
          id: 'VIN'
        },
        // {
        //   label: 'Շարժիչի համարի',
        //   id: 'EngineNo'
        // },
        // {
        //   label: 'Հենասարքի համարի',
        //   id: 'TrilerNo'
        // },
        // {
        //   label: 'Թափքի համարի',
        //   id: 'BodyNo'
        // }
      ]
    },

    columnDefs() {
      return [
        {
          headerName: 'Կազմակերպություն',
          field: 'company.name',
          width: 250,
        },
        {
          headerName: 'Ստեղծվել է',
          field: 'createdAt',
          filter: false,
          floatingFilterComponent: 'customDateFloatingFilter',
          floatingFilterComponentParams: {
            suppressFilterButton: true,
            field: 'createdAt',
            className: ''
          },
          width: 160,
          valueFormatter: this.dateFormatter
        },
        {
          headerName: 'Գույքահարկ',
          field: 'inspectionStatus',
          filter: true,
          width: 180,
          cellRendererFramework: 'CellRendererStatus'
        },
        {
          headerName: 'Մեքենա',
          field: 'ownerStatus.Owner',
          width: 180,
          filter: false,
          floatingFilterComponent: 'customFloatingFilter',
          floatingFilterComponentParams: {
            suppressFilterButton: true,
            field: 'car',
            className: ''
          },
          valueFormatter: this.carFormatter
        },
        {
          headerName: 'Համարանիշ',
          field: 'CarRegNo',
          filter: false,
          floatingFilterComponent: 'customFloatingFilter',
          floatingFilterComponentParams: {
            suppressFilterButton: true,
            field: 'CarRegNo',
            className: 'text-uppercase'
          },
          width: 150,
          valueFormatter: this.carRegNoFormatter
        },
        {
          headerName: 'Փաստաթուղթ',
          field: 'documentNumber',
          filter: false,
          floatingFilterComponent: 'customFloatingFilter',
          floatingFilterComponentParams: {
            suppressFilterButton: true,
            field: 'documentNumber',
            className: 'text-uppercase'
          },
          width: 140,
          valueFormatter: this.uppercaseFormatter
        },
        {
          headerName: 'Բնապ․ հարկ',
          field: 'EkoSum',
          filter: false,
          width: 160,
        },
      ]
    },

    columnDefsDahk() {
      return [
        {
          headerName: 'Կազմակերպություն',
          field: 'company.name',
          width: 250,
        },
        {
          headerName: 'Հարցում',
          field: 'createdAt',
          filter: false,
          floatingFilterComponent: 'customDateFloatingFilter',
          floatingFilterComponentParams: {
            suppressFilterButton: true,
            field: 'createdAt',
            className: ''
          },
          width: 160,
          valueFormatter: this.dateFormatter
        },
        {
          headerName: 'ԴԱՀԿ Օր',
          field: 'dahkDate',
          filter: false,
          floatingFilterComponent: 'customDateFloatingFilter',
          floatingFilterComponentParams: {
            suppressFilterButton: true,
            field: 'dahkDate',
            className: ''
          },
          width: 160
        },
        {
          headerName: 'Մեքենա',
          field: 'car',
          width: 180,
          filter: false,
          floatingFilterComponent: 'customFloatingFilter',
          floatingFilterComponentParams: {
            suppressFilterButton: true,
            field: 'car',
            className: ''
          }
        },
        {
          headerName: 'Համարանիշ',
          field: 'CarRegNo',
          filter: false,
          floatingFilterComponent: 'customFloatingFilter',
          floatingFilterComponentParams: {
            suppressFilterButton: true,
            field: 'CarRegNo',
            className: 'text-uppercase'
          },
          width: 150,
          valueFormatter: this.carRegNoFormatter
        },
        {
          headerName: 'Փաստաթուղթ',
          field: 'documentNumber',
          filter: false,
          floatingFilterComponent: 'customFloatingFilter',
          floatingFilterComponentParams: {
            suppressFilterButton: true,
            field: 'documentNumber',
            className: 'text-uppercase'
          },
          width: 140,
          valueFormatter: this.uppercaseFormatter
        },
      ]
    },


    checkups() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].checkups
    },

    dahk() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].dahk
    },

    inspectionStatusCount() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].inspectionStatusCount
    },

    pagination() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].pagination
    },

    dahkPagination() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].dahkPagination
    },

    filters: {
      get() {
        return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].filters
      },
      set(data) {
        this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_INITIAL_FILTERS', data)
      }
    },

    table_class() {
      return (this.isDark) ? 'tech-service-table ag-theme-alpine-dark w-100 ag-grid-table' : 'tech-service-table ag-theme-alpine w-100 ag-grid-table'
    },

    localization() {
      return AG_GRID_LOCALE_HY
    },

    companies() {
      return [
        {
          label: 'Կազմակերպություն',
          value: null,
          disabled: true
        },
        ...this.$store.state[this.COMPANIES_STORE_MODULE_NAME].companies.map(e => ({
          label: e.name,
          id: e._id
        }))
      ]
    },

    ownerStatuses() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].ownerStatuses
    },

    nextCheckups() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].nextCheckups
    },

  },

  beforeMount() {
    this.components = {
      customFloatingFilter: this.getCustomFloatingFilterComponent(),
      customDateFloatingFilter: this.getCustomDateFloatingFilterComponent(),
      customSelectFloatingFilter: this.getCustomSelectFloatingFilterComponent(),
    }
  },

  mounted() {
  },

  created() {
    if (!store.hasModule(this.TECH_SERVICE_STORE_MODULE_NAME)) store.registerModule(this.TECH_SERVICE_STORE_MODULE_NAME, techServiceModule)
    if (!store.hasModule(this.COMPANIES_STORE_MODULE_NAME)) store.registerModule(this.COMPANIES_STORE_MODULE_NAME, companyModule)
    if (!store.hasModule(this.CARS_STORE_MODULE_NAME)) store.registerModule(this.CARS_STORE_MODULE_NAME, carsModule)
    this.getControls()
  },

  destroyed() {
    this.filters = this.initialFilters
  },

  methods: {

    downloadInvoice() {
      this.getAllControls();
    },

    getAllControls() {
      this.$vs.loading()
      this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/getAllDahk', this.serialize(this.filters))
          .then(response => {
            this.allDahk = response.dahk;
            setTimeout(() => {
              TableToExcel.convert(document.getElementById("exportTable"), {
                name: 'Դահկ Հաշվետվություն.xlsx',
                sheet: {
                  name: "Տեխզննում"
                }
              });
              this.$vs.loading.close()
            }, 2000)
          })
          .catch((error) => {
            this.$root.$emit('getError', error)
            this.$vs.loading.close()
          })
    },

    changeControllFilter(name) {
      this.controlFilter = name
    },

    changeSearchTypeMobile(id) {
      this.filters.type = id
      this.getControls()
    },

    getOperators() {
      this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/getOperators')
    },

    onGridReady(params) {
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_GRID_API', params.api)
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_GRID_COLUMN_API', params.columnApi)
    },

    getControls() {
      this.$vs.loading()
      const filters = JSON.parse(JSON.stringify(this.filters))
      const { fields } = filters
      delete filters.fields
      filters.columnFilter = JSON.stringify(fields)
      const filterData = {
        current_page: this.pagination.current_page,
        current_page_dahk: this.dahkPagination.current_page,
        ...filters
      }
      this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/getControls', this.serialize(filterData))
          .then(() => {
            this.$vs.loading.close()
          })
          .catch((error) => {
            this.$root.$emit('getError', error)
            this.$vs.loading.close()
          })
    },

    serialize(obj) {
      let str = []
      for (let p in obj) {
        if (obj.hasOwnProperty(p)) {
          if (typeof obj[p] !== 'undefined' && obj[p] !== null && obj[p] !== '') {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
          }
        }
      }
      return str.join('&')
    },

    changePage(page) {
      this.pagination.current_page = page
      this.getControls()
    },

    changeDahkPage(page) {
      console.log('page', page)
      this.dahkPagination.current_page = page
      this.getControls()
    },

    disableSymbol(e) {
      if (!(/^[*a-zA-Z0-9ղցվա]*$/.test(e.key))) {
        e.preventDefault()
      }
    },

    changeCompany() {
      this.getControls()
    },

    searchCheckup() {
      this.getControls()
    },

    ownerStatusFormatter(params) {
      const index = this.ownerStatuses.findIndex((i) => i.id === params.value)
      if (index >= 0) {
        return this.ownerStatuses[index].title
      }
      return ''
    },

    companyAddressFormatter(params) {
      return params.value.region + ' ' + params.value.county + ' ' + params.value.street + ' ' + params.value.address
    },

    carFormatter(params) {
      return params.data.car + ' ' + params.data.car_model
    },

    carRegNoFormatter(params) {
      return `${params.data.CarRegNo} ${(params.data.YellowCarRegNo) ? `(${params.data.YellowCarRegNo})` : ''}`
    },

    bodyNameFormatter(params) {
      if (params.value) {
        return params.value
      } else {
        let bodyIndex = this.bodies.findIndex((b) => b.id === params.data.body_id)
        if (bodyIndex >= 0) {
          return this.bodies[bodyIndex].name
        }
      }
    },

    uppercaseFormatter(params) {
      return (params.value) ? params.value.toLocaleUpperCase() : params.value
    },

    dateFormatter(params) {
      return this.$options.filters.formatDate(params.value)
    },

    dayFormatter(params) {
      return this.$options.filters.formatDay(params.value)
    },

    nextCheckupFormatter(params) {
      const index = this.nextCheckups.findIndex((e) => e.value == params.value)
      if (index >= 0) {
        return this.nextCheckups[index].label
      }
      return params.value
    },

    nextCheckupName(value) {
      const index = this.nextCheckups.findIndex((e) => e.value == value)
      if (index >= 0) {
        return this.nextCheckups[index].label
      }
      return value
    },

    appendFilter(field, value) {
      this.filters.fields[field] = value
      this.getControls()
    },

    appendDateFilter(field, value) {
      let start = new Date(value)
      start.setHours(0, 0, 0, 0)
      let end = new Date(value)
      end.setHours(23, 59, 59, 59)
      if (field === 'createdAt') {
        this.filters.startDate = start
        this.filters.endDate = end
      }
      this.getControls()
    },

    getCustomFloatingFilterComponent() {
      const methods = this

      function NumberFloatingFilter() {
      }

      NumberFloatingFilter.prototype.init = function (params) {
        this.eGui = document.createElement('div')
        this.eGui.innerHTML = '<input class="ag-input-field-input ag-text-field-input ' + params.className + '" type="text" />'
        this.currentValue = null
        this.eFilterInput = this.eGui.querySelector('input')
        const that = this
        const onInputBoxChanged = () => {
          that.currentValue = that.eFilterInput.value
          methods.appendFilter(params.field, that.currentValue)
        }
        this.eFilterInput.addEventListener('change', onInputBoxChanged)
      }
      NumberFloatingFilter.prototype.onParentModelChanged = function (parentModel) {
        if (!parentModel) {
          this.eFilterInput.value = ''
          this.currentValue = null
        } else {
          this.eFilterInput.value = parentModel.filter + ''
          this.currentValue = parentModel.filter
        }
      }
      NumberFloatingFilter.prototype.getGui = function () {
        return this.eGui
      }
      return NumberFloatingFilter
    },

    getCustomSelectFloatingFilterComponent() {
      const methods = this

      function NumberFloatingFilter() {
      }

      NumberFloatingFilter.prototype.init = function (params) {
        this.eGui = document.createElement('div')
        let select = '<select class="ag-input-field-input ag-text-field-input ' + params.className + '">'
        select += '<option value=""></option>'
        if (params.options && params.options.length > 0) {
          params.options.map((item) => {
            select += `<option value="${item.value}">${item.label}</option>`
          })
        }
        select += '</select>'
        this.eGui.innerHTML = select
        this.currentValue = null
        this.eFilterInput = this.eGui.querySelector('select')
        const that = this
        const onInputBoxChanged = () => {
          that.currentValue = that.eFilterInput.value
          methods.appendFilter(params.field, that.currentValue)
        }
        this.eFilterInput.addEventListener('change', onInputBoxChanged)
      }
      NumberFloatingFilter.prototype.onParentModelChanged = function (parentModel) {
        if (!parentModel) {
          this.eFilterInput.value = ''
          this.currentValue = null
        } else {
          this.eFilterInput.value = parentModel.filter + ''
          this.currentValue = parentModel.filter
        }
      }
      NumberFloatingFilter.prototype.getGui = function () {
        return this.eGui
      }
      return NumberFloatingFilter
    },

    getCustomDateFloatingFilterComponent() {
      const methods = this

      function NumberFloatingFilter() {
      }

      NumberFloatingFilter.prototype.init = function (params) {
        this.eGui = document.createElement('div')
        this.eGui.className = 'w-100'
        this.eGui.innerHTML = '<input data-date-format="DD/MM/YYYY" class="ag-input-field-input ag-text-field-input ' + params.className + '" type="date" />'
        this.currentValue = null
        this.eFilterInput = this.eGui.querySelector('input')
        const that = this
        const onInputBoxChanged = () => {
          that.currentValue = that.eFilterInput.value
          methods.appendDateFilter(params.field, that.currentValue)
        }
        this.eFilterInput.addEventListener('change', onInputBoxChanged)
      }
      NumberFloatingFilter.prototype.onParentModelChanged = function (parentModel) {
        if (!parentModel) {
          this.eFilterInput.value = ''
          this.currentValue = null
        } else {
          this.eFilterInput.value = parentModel.filter + ''
          this.currentValue = parentModel.filter
        }
      }
      NumberFloatingFilter.prototype.getGui = function () {
        return this.eGui
      }
      return NumberFloatingFilter
    },

  }

}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#tech-service-all {

  .ag-header {
    min-height: inherit !important;

    .ag-header-row-floating-filter {
      display: none !important;
    }

  }


  .tech-service-table {
    max-height: calc(var(--vh, 1vh) * 100 - 13rem);
    min-height: calc(var(--vh, 1vh) * 100 - 13rem);
    height: calc(var(--vh, 1vh) * 100 - 13rem);
    padding: 0.5rem;
  }

  .table-footer {
    padding-bottom: 10px !important;
  }
}
</style>
<style lang="scss" scoped>
@media (max-width: 768px) {
  [dir=ltr] .col-xl, [dir=ltr] .col-xl-auto, [dir=ltr] .col-xl-12, [dir=ltr] .col-xl-11, [dir=ltr] .col-xl-10, [dir=ltr] .col-xl-9, [dir=ltr] .col-xl-8, [dir=ltr] .col-xl-7, [dir=ltr] .col-xl-6, [dir=ltr] .col-xl-5, [dir=ltr] .col-xl-4, [dir=ltr] .col-xl-3, [dir=ltr] .col-xl-2, [dir=ltr] .col-xl-1, [dir=ltr] .col-lg, [dir=ltr] .col-lg-auto, [dir=ltr] .col-lg-12, [dir=ltr] .col-lg-11, [dir=ltr] .col-lg-10, [dir=ltr] .col-lg-9, [dir=ltr] .col-lg-8, [dir=ltr] .col-lg-7, [dir=ltr] .col-lg-6, [dir=ltr] .col-lg-5, [dir=ltr] .col-lg-4, [dir=ltr] .col-lg-3, [dir=ltr] .col-lg-2, [dir=ltr] .col-lg-1, [dir=ltr] .col-md, [dir=ltr] .col-md-auto, [dir=ltr] .col-md-12, [dir=ltr] .col-md-11, [dir=ltr] .col-md-10, [dir=ltr] .col-md-9, [dir=ltr] .col-md-8, [dir=ltr] .col-md-7, [dir=ltr] .col-md-6, [dir=ltr] .col-md-5, [dir=ltr] .col-md-4, [dir=ltr] .col-md-3, [dir=ltr] .col-md-2, [dir=ltr] .col-md-1, [dir=ltr] .col-sm, [dir=ltr] .col-sm-auto, [dir=ltr] .col-sm-12, [dir=ltr] .col-sm-11, [dir=ltr] .col-sm-10, [dir=ltr] .col-sm-9, [dir=ltr] .col-sm-8, [dir=ltr] .col-sm-7, [dir=ltr] .col-sm-6, [dir=ltr] .col-sm-5, [dir=ltr] .col-sm-4, [dir=ltr] .col-sm-3, [dir=ltr] .col-sm-2, [dir=ltr] .col-sm-1, [dir=ltr] .col, [dir=ltr] .col-auto, [dir=ltr] .col-12, [dir=ltr] .col-11, [dir=ltr] .col-10, [dir=ltr] .col-9, [dir=ltr] .col-8, [dir=ltr] .col-7, [dir=ltr] .col-6, [dir=ltr] .col-5, [dir=ltr] .col-4, [dir=ltr] .col-3, [dir=ltr] .col-2, [dir=ltr] .col-1 {
    padding-right: 0;
    padding-left: 0;
  }
}
.companyInfo {
  display: flex;
  flex-direction: column;
  .infoItem {
    display: flex;
    justify-content: flex-start;
    flex-flow: row;
    margin-bottom: 15px;
    .label {
      width: 50%;
    }
    .value {
      width: 50%;
      color: #000;
      font-weight: bold;
      font-size: 0.9rem;
    }
  }
}

</style>
<style lang="scss">
.ag-header-cell-label, .ag-cell {
  justify-content: center!important;
  text-align: center;
}
.filters {
  display: flex;
  width: 100%;
  .control-filter {
    color: #000;
    font-weight: bold;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    padding: 7px 5px;
    width: auto;
    margin-right: 10px;
    cursor: pointer;
    justify-content: space-between;
    &.active {
      background: #eee;
    }
    .filter-label {
      opacity: 1;
    }
    .filter-value {
      width: auto;
      height: 18px;
      background: var(--danger);
      color: #fff;
      padding: 0 15px;
      margin: 0 7px;
      border-radius: 6px;
    }
  }
}
#exportTable {
  width: 100%;
  td, th {
    border-top: 1px solid #000;
    border-left: 1px solid #000;
    font-size: 10px;
    color: #000;
    text-align: center;
    padding: 6px 0;
    width: 300px;

    &:last-child {
      border-right: 1px solid #000;
    }
  }
  tr:last-child {
    td {
      border-bottom: 1px solid #000;
    }
  }
}
.downloadInvoiceButton {
  padding: 0 11px;
  font-size: 13px;
  height: 28px;
  margin-top: 3px;
}
</style>
