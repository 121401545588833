<template>
  <section class="financial-preview-wrapper">


    <b-row class="financial-preview">

      <b-col
          cols="12"
          md="4"
          xl="3"
          class="invoice-actions"
      >
        <b-card>

          <!-- Button: CLose -->
          <b-button
              variant="outline-secondary"
              class="mb-75"
              block
              @click="closeDoc"
          >
            <feather-icon
                icon="XCircleIcon"
                class="mr-50"
            />
            Փակել
          </b-button>

          <template v-if="userType === 'ADMIN' || userType==='VIEWER'">
            <v-select
                @input="changeCompany"
                placeholder="Կազմակերպություն"
                id="vue-select"
                :reduce="prefix => prefix.id"
                v-model="filters.company"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="companies"
                class="mb-1"
            />
          </template>

          <date-range-picker
              ref="picker"
              :opens="'center'"
              v-model="dateRange"
              :locale-data="localeData"
              :ranges="dateRanges"
              :always-show-calendars="false"
              @update="getCheckups"
          >
            <template v-slot:input="picker" class="w-100-p">
              <span v-if="picker.startDate === null">Բոլորը</span>
              <span v-else-if="$options.filters.dateHy(picker.startDate) === $options.filters.dateHy(picker.endDate)">{{
                  picker.startDate | dateHy
                }} </span>
              <span v-else>{{ picker.startDate | dateHy }} - {{ picker.endDate | dateHy }}</span>
            </template>
          </date-range-picker>

          <!-- Button: Send Invoice --><b-button
            variant="success"
            class="mb-75"
            block
            @click="exportExcel"
        >
          <feather-icon
              icon="DownloadIcon"
              class="mr-50"
          />
          <span>Բեռնել</span>
        </b-button>

<!--          <b-dropdown-->
<!--              class="w-100"-->
<!--              split-->
<!--              text="Բեռնել"-->
<!--              variant="success"-->
<!--              right-->
<!--              @click="exportExcel"-->
<!--          >-->
<!--            <b-dropdown-item @click="exportExcel">-->
<!--              Excel-->
<!--            </b-dropdown-item>-->
<!--            <b-dropdown-item @click="exportPDF">-->
<!--              PDF-->
<!--            </b-dropdown-item>-->
<!--          </b-dropdown>-->


        </b-card>
      </b-col>

      <b-col
          cols="12"
          xl="9"
          md="8"
      >
        <b-card
            no-body
            class="financial-preview-card"
            ref="document"
        >
          <!-- Header -->
          <b-card-body class="pb-0">
            <table data-cols-width="3,8,8,8,8,8,8,8,8,8,8" id="headerTable" class="financial-table">
              <thead>
              <tr>
                <th colspan="11" class="border-0"></th>
              </tr>
              <tr>
                <th data-f-bold="true" data-a-h="center" class="border-0" colspan="10">
                  <p>ՀԱՇՎԵՏՎՈՒԹՅՈՒՆ</p>
                  <p>ՏԵԽՆԻԿԱԿԱՆ ԶՆՆՈՒԹՅՈՒՆ ԱՆՑԱԾ ՏՐԱՆՍՊՈՐՏԱՅԻՆ ՄԻՋՈՑՆԵՐԻ ՎԵՐԱԲԵՐՅԱԼ</p>
                </th>
                <th class="border-0">Ձև 2</th>
              </tr>
              <tr>
                <th colspan="11" class="border-0"></th>
              </tr>
              <tr v-if="company">
                <td class="border-0"></td>
                <th class="border-0  text-left">
                  <template>
                    <span>{{ dateRange.startDate | formatDayFinancial }} - {{
                        dateRange.endDate | formatDayFinancial
                      }}</span>
                  </template>
                </th>
                <th colspan="9" class="border-0 text-left">
                  {{company.name}}
                </th>
              </tr>
              <tr v-if="company">
                <td class="border-0"></td>
                <th class="border-0  text-left">
                  Գտնվելու վայրը
                </th>
                <th colspan="9" class="border-0 text-left">
                  {{company.region}} {{company.county}} {{company.street}} {{company.address}}
                </th>
              </tr>
              <tr>
                <th colspan="11" class="border-0"></th>
              </tr>
              <tr>
                <th data-height="150" data-b-a-s="thin" rowspan="3">Հ/Հ</th>
                <th data-height="150" data-b-a-s="thin" rowspan="3">Անվանումը</th>
                <th data-height="50" data-a-h="center" data-b-a-s="thin" colspan="9">Տրանսպորտային միջոցի տեսակը</th>
              </tr>
              <tr>
                <th data-height="100" data-b-a-s="thin" rowspan="2">Թեթև մարդատար<br>(Թ/Մ)></th>
                <th data-height="50" data-b-a-s="thin" colspan="2" >Ավտոբուսներ</th>
                <th data-height="50" data-b-a-s="thin" colspan="3">Բեռնատար կողավոր և մասնագիտացված ավտոմոբիլներ</th>
                <th data-height="100" data-b-a-s="thin" rowspan="2">Կիսակցորդներ<br>(Կիս./Կ)</th>
                <th data-height="100" data-b-a-s="thin" rowspan="2">Կցորդներ<br>(ԿՑ)</th>
                <th data-height="100" data-b-a-s="thin" rowspan="2">Մոտոցիկլետ<br>(Մոտ)</th>
              </tr>
              <tr>
                <th data-height="50" data-b-a-s="thin">9-17 նստատեղ ունեցող (Մ/Ա)</th>
                <th data-height="50" data-b-a-s="thin">17-ից ավել նստատեղ ունեցող (Ա/Բ)</th>
                <th data-height="50" data-b-a-s="thin">մինչև 3.5 տոննա լրիվ զանգվածով (Փ/Բ)</th>
                <th data-height="50" data-b-a-s="thin">3.5 -12 տոննա լրիվ զանգվածով (Միջ/Բ)</th>
                <th data-height="50" data-b-a-s="thin">12 տոննայից ավելի լրիվ զանգվածով (Մեծ/Բ)</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <th data-b-a-s="thin">1</th>
                <th data-b-a-s="thin">Ներկայացվել են տեխնիկական զննության</th>
                <th data-b-a-s="thin">{{result.nerkayacvac[0]}}</th>
                <th data-b-a-s="thin">{{result.nerkayacvac[1]}}</th>
                <th data-b-a-s="thin">{{result.nerkayacvac[2]}}</th>
                <th data-b-a-s="thin">{{result.nerkayacvac[3]}}</th>
                <th data-b-a-s="thin">{{result.nerkayacvac[4]}}</th>
                <th data-b-a-s="thin">{{result.nerkayacvac[5]}}</th>
                <th data-b-a-s="thin">{{result.nerkayacvac[6]}}</th>
                <th data-b-a-s="thin">{{result.nerkayacvac[7]}}</th>
                <th data-b-a-s="thin">{{result.nerkayacvac[8]}}</th>
              </tr>
              <tr>
                <th data-b-a-s="thin" rowspan="2">2</th>
                <th data-b-a-s="thin">Ճանաչվել են սարքին</th>
                <th data-b-a-s="thin">{{result.sarqin[0]}}</th>
                <th data-b-a-s="thin">{{result.sarqin[1]}}</th>
                <th data-b-a-s="thin">{{result.sarqin[2]}}</th>
                <th data-b-a-s="thin">{{result.sarqin[3]}}</th>
                <th data-b-a-s="thin">{{result.sarqin[4]}}</th>
                <th data-b-a-s="thin">{{result.sarqin[5]}}</th>
                <th data-b-a-s="thin">{{result.sarqin[6]}}</th>
                <th data-b-a-s="thin">{{result.sarqin[7]}}</th>
                <th data-b-a-s="thin">{{result.sarqin[8]}}</th>
              </tr>
              <tr>
                <th data-b-a-s="thin">որոնցից II անգամ ստուգվելուց հոտո</th>
                <th data-b-a-s="thin">{{result.doubleChecked[0]}}</th>
                <th data-b-a-s="thin">{{result.doubleChecked[1]}}</th>
                <th data-b-a-s="thin">{{result.doubleChecked[2]}}</th>
                <th data-b-a-s="thin">{{result.doubleChecked[3]}}</th>
                <th data-b-a-s="thin">{{result.doubleChecked[4]}}</th>
                <th data-b-a-s="thin">{{result.doubleChecked[5]}}</th>
                <th data-b-a-s="thin">{{result.doubleChecked[6]}}</th>
                <th data-b-a-s="thin">{{result.doubleChecked[7]}}</th>
                <th data-b-a-s="thin">{{result.doubleChecked[8]}}</th>
              </tr>
              <tr>
                <th data-b-a-s="thin" rowspan="5">3</th>
                <th data-b-a-s="thin">Սարքին տրանսպորտային միջոցների թիվն ըստ օգտագործվող վառելիքի`</th>
                <th data-b-a-s="thin" colspan="9" class="border-0"></th>
              </tr>
              <tr>
                <th data-b-a-s="thin">1) բենզին (Բ)</th>
                <th data-b-a-s="thin">{{result.benzin[0]}}</th>
                <th data-b-a-s="thin">{{result.benzin[1]}}</th>
                <th data-b-a-s="thin">{{result.benzin[2]}}</th>
                <th data-b-a-s="thin">{{result.benzin[3]}}</th>
                <th data-b-a-s="thin">{{result.benzin[4]}}</th>
                <th data-b-a-s="thin">{{result.benzin[5]}}</th>
                <th data-b-a-s="thin">{{result.benzin[6]}}</th>
                <th data-b-a-s="thin">{{result.benzin[7]}}</th>
                <th data-b-a-s="thin">{{result.benzin[8]}}</th>
              </tr>
              <tr>
                <th data-b-a-s="thin">2) դիզելային վառելիք (Դ)</th>
                <th data-b-a-s="thin">{{result.dizel[0]}}</th>
                <th data-b-a-s="thin">{{result.dizel[1]}}</th>
                <th data-b-a-s="thin">{{result.dizel[2]}}</th>
                <th data-b-a-s="thin">{{result.dizel[3]}}</th>
                <th data-b-a-s="thin">{{result.dizel[4]}}</th>
                <th data-b-a-s="thin">{{result.dizel[5]}}</th>
                <th data-b-a-s="thin">{{result.dizel[6]}}</th>
                <th data-b-a-s="thin">{{result.dizel[7]}}</th>
                <th data-b-a-s="thin">{{result.dizel[8]}}</th>
              </tr>
              <tr>
                <th data-b-a-s="thin">3) բնական գազ (Բ/Գ)</th>
                <th data-b-a-s="thin">{{result.gaz[0]}}</th>
                <th data-b-a-s="thin">{{result.gaz[1]}}</th>
                <th data-b-a-s="thin">{{result.gaz[2]}}</th>
                <th data-b-a-s="thin">{{result.gaz[3]}}</th>
                <th data-b-a-s="thin">{{result.gaz[4]}}</th>
                <th data-b-a-s="thin">{{result.gaz[5]}}</th>
                <th data-b-a-s="thin">{{result.gaz[6]}}</th>
                <th data-b-a-s="thin">{{result.gaz[7]}}</th>
                <th data-b-a-s="thin">{{result.gaz[8]}}</th>
              </tr>
              <tr>
                <th data-b-a-s="thin">4) հեղուկացված նավթային գազ (Հ/Գ)</th>
                <th data-b-a-s="thin">{{result.hexuk_gaz[0]}}</th>
                <th data-b-a-s="thin">{{result.hexuk_gaz[1]}}</th>
                <th data-b-a-s="thin">{{result.hexuk_gaz[2]}}</th>
                <th data-b-a-s="thin">{{result.hexuk_gaz[3]}}</th>
                <th data-b-a-s="thin">{{result.hexuk_gaz[4]}}</th>
                <th data-b-a-s="thin">{{result.hexuk_gaz[5]}}</th>
                <th data-b-a-s="thin">{{result.hexuk_gaz[6]}}</th>
                <th data-b-a-s="thin">{{result.hexuk_gaz[7]}}</th>
                <th data-b-a-s="thin">{{result.hexuk_gaz[8]}}</th>
              </tr>
              <tr>
                <th data-b-a-s="thin">4</th>
                <th data-b-a-s="thin">Ընդամենը</th>
                <th data-b-a-s="thin">{{result.all[0]}}</th>
                <th data-b-a-s="thin">{{result.all[1]}}</th>
                <th data-b-a-s="thin">{{result.all[2]}}</th>
                <th data-b-a-s="thin">{{result.all[3]}}</th>
                <th data-b-a-s="thin">{{result.all[4]}}</th>
                <th data-b-a-s="thin">{{result.all[5]}}</th>
                <th data-b-a-s="thin">{{result.all[6]}}</th>
                <th data-b-a-s="thin">{{result.all[7]}}</th>
                <th data-b-a-s="thin">{{result.all[8]}}</th>
              </tr>
              <tr>
                <th data-b-a-s="thin">5</th>
                <th data-b-a-s="thin" colspan="9" class="border-0"></th>
                <th data-b-a-s="thin">{{result.total}}</th>
              </tr>
              <tr>
                <th class="border-0" colspan="11"></th>
              </tr>
              <tr v-if="company">
                <td class="border-0" colspan="3">Տեխնիկական զննության կայանի ղեկավար`</td>
                <td class="border-0" colspan="2">____________________________________</td>
                <td class="border-0 text-left" colspan="6">{{company.boss}}</td>
              </tr>
              </tbody>
            </table>

          </b-card-body>

        </b-card>
      </b-col>


    </b-row>
  </section>
</template>

<script>

import techServiceModule from '@/store/tech-service/techServiceModule'
import { BRow, BCol, BCard, BCardBody, BButton, BDropdown, BDropdownItem } from 'bootstrap-vue'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import store from '@/store'
import vSelect from 'vue-select'
import companyModule from '@/store/companies/companyModule'
import carsModule from '@/store/cars/cars'
import TableToExcel from '@linways/table-to-excel'
// import { jsPDF } from "jspdf";

let todayStart = new Date()
todayStart.setHours(0, 0, 0, 0)

let todayEnd = new Date()
todayEnd.setHours(23, 59, 59, 59)

export default {
  name: 'fuel-doc',
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    DateRangePicker,
    vSelect,
    BDropdown,
    BDropdownItem
  },
  data() {
    return {
      multipleValues: [0, 1, 2],
      optionsMultiple: [
        {
          id: 1,
          label: '1 տարուց'
        },
        {
          id: 2,
          label: '2 տարուց'
        }
      ],
      TECH_SERVICE_STORE_MODULE_NAME: 'techServiceModule',
      COMPANIES_STORE_MODULE_NAME: 'companiesModule',
      CARS_STORE_MODULE_NAME: 'carsModule',
      filters: {
        company: null
      },
      dateRange: {
        startDate: todayStart,
        endDate: todayEnd
      },
      localeData: {
        applyLabel: 'Հաստատել',
        cancelLabel: 'Չեղարկել',
        ranges: [],
        firstDay: 1,
        format: 'dd/mm/yyyy',
        customRangeLabel: 'Այլ',
        daysOfWeek: ['Կիր', 'Երկ', 'Երք', 'Չոր', 'Հնգ', 'Ուրբ', 'Շբթ'],
        monthNames: ['Հուն', 'Փետ', 'Մար', 'Ապր', 'Մայ', 'Հուն', 'Հուլ', 'Օգ', 'Սեպ', 'Հոկ', 'Նոյ', 'Դեկ'],
      },
    }
  },
  computed: {

    checkups() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].fuel_checkups
    },

    car_types() {
      return this.$store.state[this.CARS_STORE_MODULE_NAME].car_types
    },

    fields () {
      return {
        nerkayacvac: {},
        sarqin: {},
        doubleChecked: {},
        benzin: {},
        dizel: {},
        gaz: {},
        hexuk_gaz: {}
      }
    },

    result () {
      const result = {
        nerkayacvac: [],
        sarqin: [],
        doubleChecked: [],
        benzin: [],
        dizel: [],
        gaz: [],
        hexuk_gaz: [],
        all: [],
        total: 0
      }

      // Ներկայացված է տեխզննման
      for (let car_type of this.car_types) {
        let value = 0
        if (this.checkups.length > 0) {
          value = this.checkups.reduce((val, itm) => (itm.car_type === car_type.name) ? val + 1 : val + 0, 0)
        }
        result.nerkayacvac.push(value)
      }

      // Ճանաչվել է սարքին
      for (let car_type of this.car_types) {
        let value = 0
        if (this.checkups.length > 0) {
          value = this.checkups.reduce((val, itm) => (itm.DoubleChecked === false &&  itm.car_type === car_type.name) ? val + 1 : val + 0, 0)
        }
        result.sarqin.push(value)
      }

      // Երկրորդ ստուգումից հետո
      for (let car_type of this.car_types) {
        let value = 0
        if (this.checkups.length > 0) {
          value = this.checkups.reduce((val, itm) => (itm.DoubleChecked === true && itm.car_type === car_type.name) ? val + 1 : val + 0, 0)
        }
        result.doubleChecked.push(value)
      }

      // Բենզին
      for (let car_type of this.car_types) {
        let value = 0
        if (this.checkups.length > 0) {
          value = this.checkups.reduce((val, itm) => (itm.Opinion === true && itm.car_type === car_type.name && itm.fuel === 'Բենզին') ? val + 1 : val + 0, 0)
        }
        result.benzin.push(value)
      }

      // Դիզել
      for (let car_type of this.car_types) {
        let value = 0
        if (this.checkups.length > 0) {
          value = this.checkups.reduce((val, itm) => (itm.Opinion === true && itm.car_type === car_type.name && itm.fuel === 'Դիզելային վառելիք') ? val + 1 : val + 0, 0)
        }
        result.dizel.push(value)
      }

      // Բնական գազ
      for (let car_type of this.car_types) {
        let value = 0
        if (this.checkups.length > 0) {
          value = this.checkups.reduce((val, itm) => (itm.Opinion === true && itm.car_type === car_type.name && itm.fuel === 'Բնական գազ') ? val + 1 : val + 0, 0)
        }
        result.gaz.push(value)
      }

      // 	Հեղուկացված նավթային գազ
      for (let car_type of this.car_types) {
        let value = 0
        if (this.checkups.length > 0) {
          value = this.checkups.reduce((val, itm) => (itm.Opinion === true && itm.car_type === car_type.name && itm.fuel === 'Հեղուկացված նավթային գազ') ? val + 1 : val + 0, 0)
        }
        result.hexuk_gaz.push(value)
      }

      // Ընդամենը
      for (let car_type of this.car_types) {
        let value = 0
        if (this.checkups.length > 0) {
          value = this.checkups.reduce((val, itm) => (itm.Opinion === true && itm.car_type === car_type.name) ? val + 1 : val + 0, 0)
        }
        result.all.push(value)
      }

      // Բոլորը
      result.total = this.checkups.reduce((val, itm) => (itm.Opinion === true) ? val + 1 : val + 0, 0)

      return result
    },

    userType () {
      return atob(this.$store.state.user_type)
    },

    companies () {
      return this.$store.state[this.COMPANIES_STORE_MODULE_NAME].companies.map(e => ({
        ...e,
        label: e.name,
        id: e._id
      }))
    },

    company: {
      get () {
        if (this.userType === 'TECHNICAL_SERVICE_OPERATOR') {
          return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].company
        }
        else if (this.userType === 'TECHNICAL_SERVICE_OWNER') {
          return this.$store.state[this.COMPANIES_STORE_MODULE_NAME].companies[0]
        }
        return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].company
      },
      set (data) {
        this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_COMPANY', data)
      }
    },

    dateRanges() {
      // Today
      let today = new Date()

      let allStart = new Date(this.firstCheckupDate)

      let todayStart = new Date()
      let todayEnd = new Date()

      // Yesterday
      let yesterdayStart = new Date()
      yesterdayStart.setDate(today.getDate() - 1)
      let yesterdayEnd = new Date()
      yesterdayEnd.setDate(today.getDate() - 1)

      // This week
      let thisWeekStart = new Date(new Date().setDate(new Date().getDate() + 1 - new Date().getDay()))
      let thisWeekEnd = new Date(new Date().setDate(new Date().getDate() + 1 - new Date().getDay() + 6))

      // Last Week
      let lastWeekStart = new Date(new Date().setDate(new Date().getDate() - 6 - new Date().getDay()))
      let lastWeekEnd = new Date(new Date().setDate(new Date().getDate() - new Date().getDay()))

      // This Month
      let thisMonthStart = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
      let thisMonthEnd = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)

      // Last Month
      let lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1)
      let lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0)

      // This Quarter
      let currentMonth = today.getMonth()
      let currentYear = today.getFullYear()
      let thisQuarterStart
      let thisQuarterEnd
      let lastQuarterStart
      let lastQuarterEnd
      if (currentMonth >= 0 && currentMonth < 3) {
        thisQuarterStart = new Date(today.getFullYear(), 0, 1)
        thisQuarterEnd = new Date(new Date().getFullYear(), 2, 31)

        lastQuarterStart = new Date(today.getFullYear() - 1, 9, 1)
        lastQuarterEnd = new Date(today.getFullYear() - 1, 11, 31)
      }
      else if (currentMonth >= 3 && currentMonth < 6) {
        thisQuarterStart = new Date(today.getFullYear(), 3, 1)
        thisQuarterEnd = new Date(new Date().getFullYear(), 5, 30)

        lastQuarterStart = new Date(today.getFullYear(), 0, 1)
        lastQuarterEnd = new Date(new Date().getFullYear(), 2, 31)
      }
      else if (currentMonth >= 6 && currentMonth < 9) {
        thisQuarterStart = new Date(today.getFullYear(), 6, 1)
        thisQuarterEnd = new Date(new Date().getFullYear(), 8, 30)

        lastQuarterStart = new Date(today.getFullYear(), 3, 1)
        lastQuarterEnd = new Date(new Date().getFullYear(), 5, 30)
      }
      else if (currentMonth >= 9 && currentMonth <  12) {
        thisQuarterStart = new Date(today.getFullYear(), 9, 1)
        thisQuarterEnd = new Date(new Date().getFullYear(), 11, 31)

        lastQuarterStart = new Date(today.getFullYear(), 3, 1)
        lastQuarterEnd = new Date(new Date().getFullYear(), 5, 30)
      }

      let thisSemesterStart
      let thisSemesterEnd
      let lastSemesterStart
      let lastSemesterEnd
      if (currentMonth >= 0 && currentMonth < 5) {
        thisSemesterStart = new Date(today.getFullYear(), 0, 1)
        thisSemesterEnd = new Date(new Date().getFullYear(), 5, 30)

        lastSemesterStart = new Date(today.getFullYear() - 1, 6, 1)
        lastSemesterEnd = new Date(today.getFullYear() - 1, 11, 31)

      }
      else {
        thisSemesterStart = new Date(today.getFullYear(), 6, 1)
        thisSemesterEnd = new Date(new Date().getFullYear(), 11, 31)

        lastSemesterStart = new Date(today.getFullYear(), 0, 1)
        lastSemesterEnd = new Date(today.getFullYear(), 5, 30)
      }

      // This Year
      let thisYearStart = new Date(new Date().getFullYear(), 0, 1)
      let thisYearEnd = new Date(new Date().getFullYear(), 11, 31)

      // Last Year
      let lastYearStart = new Date(new Date().getFullYear() - 1, 0, 1)
      let lastYearEnd = new Date(new Date().getFullYear() - 1, 11, 31)

      return {
        'Այսօր': [todayStart, todayEnd],
        'Երեկ': [yesterdayStart, yesterdayEnd],
        'Այս շաբաթ': [thisWeekStart, thisWeekEnd],
        'Անցած շաբաթ': [lastWeekStart, lastWeekEnd],
        'Այս ամիս': [thisMonthStart, thisMonthEnd],
        'Անցած ամիս': [lastMonthStart, lastMonthEnd],
        'Այս եռամսյակ': [thisQuarterStart, thisQuarterEnd],
        'Անցած եռամսյակ': [lastQuarterStart, lastQuarterEnd],
        'Այս կիսամյակ': [thisSemesterStart, thisSemesterEnd],
        'Անցած կիսամյակ': [lastSemesterStart, lastSemesterEnd],
        'Այս տարի': [thisYearStart, thisYearEnd],
        'Անցած տարի': [lastYearStart, lastYearEnd],
        'Բոլորը': [allStart, todayEnd],
      }
    },

    totalSum() {
      return this.checkups.map(item => item.Sum)
          .reduce((prev, curr) => prev + curr, 0)
    },

    excel_name () {
      if (this.company) {
        return this.company.name + ', ' + this.$options.filters.dateHy(this.dateRange.startDate) + '-' + this.$options.filters.dateHy(this.dateRange.endDate) + '.xlsx'
      }
      return this.$options.filters.dateHy(this.dateRange.startDate) + '-' + this.$options.filters.dateHy(this.dateRange.endDate) + '.xlsx'

    }

  },
  created() {
    if (!store.hasModule(this.TECH_SERVICE_STORE_MODULE_NAME)) store.registerModule(this.TECH_SERVICE_STORE_MODULE_NAME, techServiceModule)
    if (!store.hasModule(this.COMPANIES_STORE_MODULE_NAME)) store.registerModule(this.COMPANIES_STORE_MODULE_NAME, companyModule)
    if (!store.hasModule(this.CARS_STORE_MODULE_NAME)) store.registerModule(this.CARS_STORE_MODULE_NAME, carsModule)
    this.getCheckups()
    this.getCompanies()
    this.getCarTypes()
  },

  destroyed() {
    this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/CLOSE_DOC', 'fuel')
  },

  setup () {
    const printInvoice = () => {
      window.print()
    }

    return {
      printInvoice,
    }
  },

  methods: {

    getCarTypes () {
      this.$store.dispatch(this.CARS_STORE_MODULE_NAME + '/getTypes')
    },

    getCompanies() {
      if (this.userType === 'TECHNICAL_SERVICE_OWNER' || this.userType === 'ADMIN' || this.userType === 'VIEWER') {
        this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/getCompanies')
      }

    },

    closeDoc () {
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/CLOSE_DOC', 'fuel')
    },

    getCheckups() {
      this.$vs.loading()
      let queryString
      if (this.dateRange.startDate !== null) {
        this.dateRange.startDate.setHours(0, 0, 0, 0)
        this.dateRange.endDate.setHours(23, 59, 59, 59)
        queryString = 'startDate=' + this.dateRange.startDate + '&endDate=' + this.dateRange.endDate
      }
      if (queryString) {
        queryString += '&nextCheckupDays=' + JSON.stringify(this.multipleValues)
      }
      else {
        queryString = 'nextCheckupDays=' + JSON.stringify(this.multipleValues)
      }

      if((this.userType === 'ADMIN' || this.userType === 'VIEWER') && this.company) {
        queryString += '&company_id=' + this.company._id
      }

      this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/getFuelCheckups', queryString)
          .then(() => {
            this.$vs.loading.close()
          })
          .catch((error) => {
            this.$vs.loading.close()
            this.$root.$emit('getError', error)
          })
    },

    changeCompany () {
      const index = this.companies.findIndex(e => e.id === this.filters.company)
      if (index >= 0) {
        this.company = this.companies[index]
      }
      this.getCheckups()
    },

    exportExcel() {
      TableToExcel.convert(document.getElementById("headerTable"), {
        name: this.excel_name,
        sheet: {
          name: "Վառելիքային հաշվետվություն"
        }
      });
    },

    exportPDF () {
      // var doc = new jsPDF('l', 'pt', 'a4');
      //
      // doc.html(document.querySelector('#headerTable'), {
      //   callback: function (doc) {
      //     doc.save('MLB World Series Winners.pdf');
      //   },
      //   margin: [60, 60, 60, 60],
      //   x: 32,
      //   y: 32,
      // });
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.financial-preview-card {
  max-height: calc(var(--vh, 1vh) * 100 - 10rem);
  min-height: calc(var(--vh, 1vh) * 100 - 10rem);
  overflow-y: scroll;

  * {
    color: #000
  }
}

.vue-daterange-picker {
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
}

.vue-daterange-picker .show-ranges.hide-calendars {
  width: 250px !important;
  min-width: 250px !important;
}

.financial-table {
  width: 100%;
  color: #000;

  thead, tbody {
    tr {
      opacity: 1;

      th, td {
        border: 1px solid #000;
        line-height: 10px;
        height: 25px;
        text-align: center;
        font-size: 10px;
      }
    }
  }
}
</style>
